<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar color="transparent mt-1" flat height="48">
        <v-toolbar-title class="ml-n3 subtitle-1">
          Folios
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          <span class="text-decoration-none secondary--text font-weight-bold">Monitor</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-card outlined rounded>
        <v-card-text class="pa-0">
          <!-- folios status -->
          <v-row align="center" no-gutters>
            <v-col class="pa-9 pb-7 text-center">
              <v-progress-circular class="mb-2" v-if="loaderReport" indeterminate color="primary" :width="2" :size="24" />
              <v-progress-circular v-else rounded :rotate="360" :size="100" :width="8" value="78" color="red lighten-1">
                <span class="d-block text-h3 text--red lighten-1 font-weight-medium">78%</span>
              </v-progress-circular>
              <v-sheet class="mt-3 transparent">
                <v-chip color="red lighten-1" dark small>Crítico</v-chip>
                <span class="d-block text--primary text-h4">Riesgo operacional</span>
                <!-- status label -->
                <!-- <v-chip v-if="item.status === 'normal'" color="green lighten-1" dark small>Normal</v-chip>
                <v-chip v-else-if="item.status === 'alert'" color="orange lighten-1" dark small>Alerta</v-chip>
                <v-chip v-else-if="item.status === 'risk'" color="red lighten-1" dark small>Crítico</v-chip> -->
                <!-- end status label -->
              </v-sheet>
            </v-col>
            <v-divider vertical />
            <v-col class="pa-0 text-center">
              <v-sheet class="pt-6 transparent" height="150">
                <v-progress-circular class="mb-2" v-if="loaderReport" indeterminate color="primary" :width="2" :size="24" />
                <span class="d-block text-h2 text--secondary font-weight-bold" v-else>28.909</span>
                <v-btn class="d-block" color="primary" :ripple="false" text :to="{ name: 'FoliosListCreate' }">Ver stock<v-icon color="primary" right size="16">mdi-open-in-new</v-icon></v-btn>
              </v-sheet>
              <span class="d-block text--primary text-h4">Folios disponibles</span>
            </v-col>
            <v-divider vertical />
            <v-col class="pa-0 text-center">
              <v-sheet class="pt-6 transparent" height="150">
                <v-progress-circular class="mb-2" v-if="loaderReport" indeterminate color="primary" :width="2" :size="24" />
                <span class="d-block text-h2 text--secondary font-weight-bold" v-else>12</span>
              </v-sheet>
              <span class="d-block text--primary text-h4">Días estimados de operación</span>
            </v-col>
          </v-row>
          <!-- end folios status -->
        </v-card-text>
      </v-card>
    </v-col>

    <!-- to report -->
    <v-col cols="6" class="pr-3">
      <v-card class="dashboardfx" hover outlined rounded :to="{ name: 'FoliosBranchReport' }">
        <v-card-text class="pa-5 text-center">
          <v-icon color="fontBody" size="45">mdi-store</v-icon>
          <span class="d-block mt-1 align-center body-1">Ir al reporte de tiendas
            <v-icon color="secondary" size="16">mdi-arrow-right</v-icon>
          </span>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" class="pl-3">
      <v-card class="dashboardfx" hover outlined rounded :to="{ name: 'FoliosPosReport' }">
        <v-card-text class="pa-5 text-center">
          <v-icon color="fontBody" size="45">mdi-printer-pos</v-icon>
          <span class="d-block mt-1 align-center body-1">Ir al reporte de POS
            <v-icon color="secondary" size="16">mdi-arrow-right</v-icon>
          </span>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- end to report -->

    <!-- summary branch -->
    <v-col cols="6">
      <v-card outlined rounded>
        <v-card-title class="py-3">
          <span class="subtitle-1 secondary--text font-weight-bold">Cantidad de tiendas por estado</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="transparent">
            <v-list-item class="py-2" :to="{ name: 'FoliosBranchReport', params: { id: 'risk' } }">
              <v-list-item-title>
                <v-chip color="red lighten-1" dark small>Crítico<v-icon class="ml-1" size="16">mdi-close</v-icon></v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1 text-right">
                <span class="font-weight-bold">3</span>
                <span class="ml-1 text--primary">tiendas</span>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider />
            <v-list-item class="py-2" :to="{ name: 'FoliosBranchReport', params: { id: 'alert' } }">
              <v-list-item-title>
                <v-chip color="orange lighten-1" dark small>Alerta<v-icon class="ml-1" size="16">mdi-information-outline</v-icon></v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1 text-right">
                <span class="font-weight-bold">2</span>
                <span class="ml-1 text--primary">tiendas</span>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider />
            <v-list-item class="py-2" :to="{ name: 'FoliosBranchReport', params: { id: 'normal' } }">
              <v-list-item-title>
                <v-chip color="green lighten-1" dark small>Normal<v-icon class="ml-1" size="16">mdi-check</v-icon></v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1 text-right">
                <span class="font-weight-bold">24</span>
                <span class="ml-1 text--primary">tiendas</span>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- end summary branch -->

    <!-- summary pos -->
    <v-col cols="6">
      <v-card outlined rounded>
        <v-card-title class="py-3">
          <span class="subtitle-1 secondary--text font-weight-bold">Cantidad de POS por estado</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="transparent">
            <v-list-item class="py-2" :to="{ name: 'FoliosPosReport', params: { id: 'risk' } }">
              <v-list-item-title>
                <v-chip color="red lighten-1" dark small>Crítico<v-icon class="ml-1" size="16">mdi-close</v-icon></v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1 text-right">
                <span class="font-weight-bold">12</span>
                <span class="ml-1 text--primary">POS</span>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider />
            <v-list-item class="py-2" :to="{ name: 'FoliosPosReport', params: { id: 'alert' } }">
              <v-list-item-title>
                <v-chip color="orange lighten-1" dark small>Alerta<v-icon class="ml-1" size="16">mdi-information-outline</v-icon></v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1 text-right">
                <span class="font-weight-bold">6</span>
                <span class="ml-1 text--primary">POS</span>
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider />
            <v-list-item class="py-2" :to="{ name: 'FoliosPosReport', params: { id: 'normal' } }">
              <v-list-item-title>
                <v-chip color="green lighten-1" dark small>Normal<v-icon class="ml-1" size="16">mdi-check</v-icon></v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="body-1 text-right">
                <span class="font-weight-bold">86</span>
                <span class="ml-1 text--primary">POS</span>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- end summary pos -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {

  mixins: [
    ListViewMixin
  ],
  data: () => ({
    linkReportsList: [
      {
        text: 'tiendas',
        icon: 'store',
        query: 'branch'
      },
      {
        text: 'POS',
        icon: 'printer-pos',
        query: 'pos'
      }
    ],
    // example
    chartData: {
      datasets: [{
        data: [346, 254, 435, 567, 234, 344, 767, 576, 786, 712, 543, 456],
        label: 'current',
        percent_change: [-13.88, 8.7, 10.93, 1.58, 3.72, 9.38, 20.78, -18.08, 2.93, 5.13, -3.37, 6.64]
      }],
      labels: ['2021-05-01', '2021-06-01', '2021-07-01', '2021-08-01', '2021-09-01', '2021-10-01', '2021-11-01', '2021-12-01', '2022-01-01', '2022-02-01', '2022-03-01', '2022-04-01']
    },
    // end expample
    headers: [
      { text: 'Estado de respuesta', value: 'code', sortable: true },
      { text: 'Documento', value: 'document' },
      { text: '', value: 'status' },
      { text: 'Fecha', value: 'date', align: 'end', sortable: true },
      { text: 'Total', value: 'total', align: 'end', sortable: true }
    ],
    loaderList: false,
    loaderReport: false,
    valuesAcknowledgment: { ERM: 0, ACD: 0, RCD: 0, RFP: 0, RFT: 0, WAR: 0 },
    statusesDocumentsStatus: [
      {
        code: 'approved',
        name: 'Documentos emitidos',
        routeQueryValue: '{"cl_status_detail": "Aceptado"}'
      },
      {
        code: 'rejected',
        name: 'Documentos recibidos',
        routeQueryValue: '{"cl_status_detail": "Rechazado"}'
      }
    ],
    valuesDocumentsStatus: { approved: 0, rejected: 0 }
  }),
  computed: mapState({
    count: state => state.irs.supplierresponsesCount,
    supplierresponsesList: state => state.irs.supplierresponsesList
  }),
  created () {
    this.loaderReport = true

    Promise.all([
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/supplierresponses/report'
      })
      .then((response) => {
        if (response.data) {
          const dataset = response.data.datasets[0].data
          const labels = response.data.labels
          this.valuesAcknowledgment = Object.fromEntries(labels.map((v, i) => [v, dataset[i]]))
        }
      }),
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/documents_status/report'
      })
      .then((response) => {
        if (response.data) {
          const dataset = response.data.datasets[0].data
          const labels = response.data.labels
          this.valuesDocumentsStatus = Object.fromEntries(labels.map((v, i) => [v, dataset[i]]))
        }
      })
    ])
    .finally(() => {
      setTimeout(() => {
        this.loaderReport = false
      }, 1000)
    })
  },
  methods: {
    getList () {
      const query = Object.assign({}, this.$route.query, {
        expand: 'documents'
      })
      this.loaderList = true

      if (!this.$route.query.code) {
        query.code__in = 'RCD,RFP,RFT'
      }

      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/supplierresponses',
        query: query
      })
      .finally(() => {
        this.loaderList = false
      })
    }
  }
}
</script>